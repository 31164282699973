.footer {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    p {
        flex: 1 0 100%;
        color: #282c34;
    }

    span {
        font-style: italic;
    }
}

.facebook {
    &:hover {
        img {
            filter: invert(26%) sepia(99%) saturate(477%) hue-rotate(184deg) brightness(96%) contrast(82%);
        }
    }
}

.linkedin{
    &:hover {
        img {
            filter: invert(34%) sepia(75%) saturate(6066%) hue-rotate(185deg) brightness(89%) contrast(101%);
        }
    }
}

.github {
    &:hover {
        img {
            filter: invert(14%) sepia(11%) saturate(13%) hue-rotate(331deg) brightness(93%) contrast(81%);
        }
    }
}

.gmail {
    &:hover {
        img {
            filter: invert(21%) sepia(56%) saturate(4904%) hue-rotate(354deg) brightness(79%) contrast(97%);
        }
    }
}

.facebook, .linkedin, .github, .gmail {
    cursor: pointer;
    margin: 0 2rem;

    @media (max-width: 480px) {
        margin: 0 1rem;
    }
}

.madeWithLove {
    font-size: 1rem;
    margin-bottom: 0px;
}

