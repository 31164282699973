.aboutContainer {
  display: flex;
  justify-content: center;
  padding: 25px 5%;
  flex-wrap: wrap;
  color: #282c34;
}

.aboutTitle {
  width: 100%;
}
