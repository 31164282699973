.tecnologiesContainer {
  background-color: #282c34;
  color: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  padding: 25px 5%;
  justify-content: center;

  h1{
    width: 100%;
  }
}