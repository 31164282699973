.professionalContainer {
  background-color: #282c34;
  color: #ffffff;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 25px 5%;
}

.containerTitle {
  width: 100%;
}

.cardsContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: auto;
}