.link {
  a {
    text-decoration: underline;
    font-size: 1rem;
    cursor: pointer;
    padding: 5px;
    border-radius: 2px;

    &:hover {
      text-decoration: none;
      animation: borderAnimation 0.5s;
      animation-direction: normal;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes borderAnimation {
  10% {font-size: 1rem}
  20% {font-size: 1.03rem}
  30% {font-size: 1.06rem}
  40% {font-size: 1.09rem}
  50% {font-size: 1.12rem}
  60% {font-size: 1.15rem}
  70% {font-size: 1.18rem}
  80% {font-size: 1.21rem}
  90% {font-size: 1.24rem}
  100% {font-size: 1.27rem}
}