.tecnology {
  margin: 0 25px;

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }
}

.tecnologyContent {
  align-self: center;
  width: 300px;
  text-align: center;
  border-top: 1px solid #ffffff;
}

.tecnologyImage {
  width: 100%;
}

.animateRotation {
  img {
    animation: spinElement 10s linear infinite;
  }
}

@keyframes spinElement {
    100% {transform: rotate(360deg)}
}

.animationStart {
  animation: slide 2.5s;
  animation-direction: normal;
  animation-iteration-count: 1;
}

@keyframes slide {
  0% {margin-right: 100%; opacity: 20%}
  50% {margin-left: 50%; opacity: 80%}
  100% {justify-content: center; opacity: 100%}
}