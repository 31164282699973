nav {
  background-color: #282c34;
  position: sticky; 
  top: 0;
  padding: 20px 10px;
  display: flex;
  transition: top 0.3s;

  justify-content: space-between;
  align-items: center;

  @media ("max-width: 480px") {
    font-size: 14px;
    padding: 10px;
    justify-content: center;
    display: none;
  }
}

.nav-left {
  justify-content: flex-start;
}

.nav-right {
  justify-content: flex-end;
}

a {
  margin: 0 2rem;

  @media ("max-width: 480px") {
    margin: 0.5rem;
  }
}